import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'
import { requireAuth, requireNoAuth } from '@/services/auth'
import Login from '@/views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/password/request-reset/',
    name: 'RequestPasswordReset',
    component: () =>
      import(/* webpackChunkName: "requestreset" */ '../views/RequestPasswordReset.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "confirmreset" */ '../views/ResetPassword.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Dashboard.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/onboarding',
    name: 'OnboardingWizard',
    component: () => import('../views/OnboardingWizard/OnboardingWizard.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/event/:eventId',
    name: 'Event Details',
    component: () => import('../views/Dashboard/EventDetails.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
})

export default router
